import {Component} from "react";

import './styles/socialbanner.css';


export default class SocialBanner extends Component {
    render() {
        return(
          <div className="social-banner-body">
              <div className="built-for-grow"> #builtforgrow </div>
              <div className="social-images">
                  <a href="https://twitter.com/andenpro" target="_blank" rel="noreferrer" className="btn">
                      <span className="socicon socicon-twitter social-icon"> </span>
                  </a>
                  <a href="https://www.facebook.com/OfficialAndenPro/" target="_blank" rel="noreferrer"  className="btn">
                      <span className="socicon socicon-facebook social-icon"> </span>
                  </a>
                  <a href="https://www.instagram.com/officialandenpro/" target="_blank" rel="noreferrer"  className="btn">
                      <span className="socicon socicon-instagram social-icon"> </span>
                  </a>
                  <a href="https://www.youtube.com/channel/UC3RcLtzetwJZ3LjhbWDFNSg" target="_blank" rel="noreferrer"  className="btn">
                      <span className="socicon socicon-youtube social-icon"> </span>
                  </a>
              </div>
          </div>
        );
    }
}
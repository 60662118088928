import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {BallTriangle} from "react-loader-spinner";


import {GetVideo} from "../apiaccess/cmsAccess";
import {ComputeVideoURL} from "../helpers/GenericHelpers";

import './styling/videoplayer.css';
import HalfBaked from "../components/HalfBaked";


export default function VideoPlayer() {
     const {videoId, chapterId} = useParams();

     const [videoData, setVideoData] = useState([])
     const [isLoading, setIsLoading] = useState(true);
     const [errored, setErrored] = useState(false);


     useEffect(() => {
        GetVideo(videoId, chapterId)
            .then(data => {
                if (data !== undefined)
                {
                    setVideoData(data);
                    setErrored(false);
                }
                else
                {
                    setErrored(true);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setErrored(true);
            });
     }, [chapterId, videoId] /*// Empty array is important, prevents a self-ddos*/);

     return (
             <div className="outer-container page-content">
                 {isLoading && <div className="loading"> <BallTriangle height="200" width="200" color="var(--and-green)" ariaLabel="loading"/> </div>}
                 {errored && <HalfBaked/>}

                 {!errored && videoData.length === 1 && videoData.map(video =>
                     <div key={video.title} className="videoContainer">
                         <div className={"video"} dangerouslySetInnerHTML={{__html: video.embed_html}} />
                         <div className="videoInfo">
                             <div className="videoHeader">
                                 {video.title}
                             </div>
                             <div className="videoDesc">
                                 {video.description}
                             </div>
                         </div>
                         <div className="episodesNav">
                             {video.next_episode !== null &&
                             <Button id="nextEp"
                                     href={ComputeVideoURL(video.chapter.chapter_number,
                                         video.next_episode.episode_number)}
                             >
                                 Next Episode <span className="arrow" >&rarr; </span>
                             </Button>
                             }
                             {video.previous_episode !== null &&
                             <Button id="previousEp"
                                     href={ComputeVideoURL(video.chapter.chapter_number,
                                         video.previous_episode.episode_number)}>
                                 <span className="arrow" >&larr; </span> Previous Episode
                             </Button>
                             }
                         </div>
                     </div>
                 )}
             </div>
     );
}
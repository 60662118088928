import React, {Component} from "react";
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/homepagebannercarousel.css";

import FiacreSlide1 from '../assets/Slider1.jpg';
import GlacierSlide1 from '../assets/GlacierCannabisBanner.jpg';
import WildFireSlide3webp from '../assets/wildfire_homepage_slider.webp';
import WildFireSlide3png from '../assets/wildfire_homepage_slider.png';
import belushiSlide from '../assets/belushis-farm-lifestyle-photo.jpg';

import {ComputeVideoURL} from "../helpers/GenericHelpers";

class HomepageBannerCarousel extends Component {
    render() {
        return (
          <Carousel className="banner" showThumbs={false}
                    swipeable={true}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={6000}
                    transitionTime={1000}
                    stopOnHover={true}
                    showStatus={false}
                    animationHandler={"fade"}>
              <CarouselItem imgSrc={belushiSlide} chapterNumber={5} episodeNumber={1} companyName="BELUSHI’S FARM" />
              <div>
                  <img src="https://rp.widen.net/content/wb30heor2v/jpeg/anden-pauls-boutique-grow-room-installed-photo-6S0A5022.jpeg"
                      alt="Sessions with Anden Chapter 4, Paul's Boutique"  />
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> PAUL'S BOUTIQUE </div>
                      <div className="banner-text episode-number"> Chapter 4</div>
                      <a href={ComputeVideoURL(4,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <img src="https://rp.widen.net/content/wb30heor2v/jpeg/anden-pauls-boutique-grow-room-installed-photo-6S0A5022.jpeg"
                      alt="Sessions with Anden Chapter 4, Paul's Boutique"  />
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> PAUL'S BOUTIQUE </div>
                      <div className="banner-text episode-number"> Chapter 4</div>
                      <a href={ComputeVideoURL(4,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <picture >
                      <source srcSet={WildFireSlide3webp} />
                      <img src={WildFireSlide3png}  alt="Sessions with Anden Chapter 3, WildFire"/>
                  </picture>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> WILDFIRE </div>
                      <div className="banner-text episode-number"> Chapter 3</div>
                      <a href={ComputeVideoURL(3,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <img alt="Sessions with Anden Chapter 2, Glacier Cannabis" src={GlacierSlide1}/>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> GLACIER CANNABIS </div>
                      <div className="banner-text episode-number"> Chapter 2</div>
                      <a href={ComputeVideoURL(2,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <img alt="Sessions with Anden Chapter 1, Fiacre, Inc. " src={FiacreSlide1}/>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> FIACRE, INC. </div>
                      <div className="banner-text episode-number"> Chapter 1</div>
                      <a href={ComputeVideoURL(1,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
          </Carousel>
        );
    }
}

export default  HomepageBannerCarousel;


function  CarouselItem ({imgSrc, companyName, chapterNumber, episodeNumber}) {
    return (
        <div>
            <img alt="Sessions with Anden Chapter 1, Fiacre, Inc. " src={imgSrc}/>
            <div className="banner-text-container">
                <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                <div className="banner-text title"> {companyName} </div>
                <div className="banner-text episode-number"> Chapter {chapterNumber}</div>
                <a href={ComputeVideoURL(chapterNumber,episodeNumber)} className="banner-text button"> WATCH NOW </a>
            </div>
        </div>);
}
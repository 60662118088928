import React, {useState, useEffect} from "react";

import './styling/chapters.css';
import {GetActiveChaptersWithActiveVideos} from "../apiaccess/cmsAccess";
import HalfBaked from "../components/HalfBaked";
import {Link} from "react-router-dom";
import {ComputeVideoURL} from "../helpers/GenericHelpers";


export default function Chapters() {
    const [chapterData, setChapterData] = useState([]);
    const [errored, setErrored] = useState(false);

    useEffect( () => {
       GetActiveChaptersWithActiveVideos(true)
           .then(data => {
               if (data !== undefined) {
                   setChapterData(data);
                   setErrored(false);
               }
               else {
                   setErrored(true);
               }
           })
           .catch(error => {
               console.log(error);
               setErrored(true)
           });
    }, []);

    return (
        <div className="chapters page-content">
            {errored && <HalfBaked/>}

            {!errored && chapterData.length >= 1 && chapterData.map(chapter =>
                <div className="chapter-content" key={chapter.chapter_number}>
                    <div className="chapter-container" id={'chapter_' + chapter.chapter_number}>
                        <div className="chap-sidebar">
                            <div className="chapter-header">
                                Chapter {chapter.chapter_number}
                            </div>
                            <div className="chapter-img">
                                <img src={chapter.cultivator.featured_image_url}
                                    alt={chapter.chapter_name + 'Logo'}
                                />
                            </div>
                        </div>
                        {chapter.video.map(vid =>
                            <div className="chapter-episode" key={vid.episode_number}
                            id={'chapter_'+chapter.chapter_number+'-ep_'+vid.episode_number}>
                                <Link to={ComputeVideoURL(chapter.chapter_number, vid.episode_number)}>
                                    <div className="play-container">
                                        <img src={vid.thumnail_url} alt={vid.title} />
                                        <span className="mdi mdi-motion-play-outline mdi-light play-icon"></span>
                                    </div>
                                </Link>
                                <div className="episode-id" >
                                    Episode {vid.episode_number}
                                </div>
                                <div className="episode-title">
                                    {vid.title}
                                </div>
                                <div className="episode-desc">
                                    {vid.description}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
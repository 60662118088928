import './styles/footer.css';
import {Link} from "react-router-dom";

export default function Footer(props) {
    let year = new Date().getFullYear();
    return(
        <div className="footer nav-footer-padding">
            <div className='nav-footer-logo-container'>
                <Link to="/">
                    <img  src={props.data} alt="Anden sessions logo"/>
                </Link>
            </div>
            <div className="link">
                <a href="https://www.anden.com" target="_blank" rel="noreferrer">
                    visit anden.com
                </a>
            </div>
            <div className="copyright">
                &copy; {year} Sessions with Anden
            </div>
        </div>
    );
}
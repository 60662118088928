import {Component} from "react";

import './styles/episodesDisplay.css';
import {GetActiveChaptersWithActiveVideos} from "../apiaccess/cmsAccess";
import {ComputeVideoURL} from "../helpers/GenericHelpers";
import {Link} from "react-router-dom";

export default class EpisodesDisplay extends Component {

    constructor() {
        super();
        this.state = {
            chapters:[]
        };
    }

    async componentDidMount() {
        try {
            const chapters = await GetActiveChaptersWithActiveVideos(true);
            this.setState({chapters: [...chapters] });
        }
        catch (error) {
            console.log(error);
        }
    }

     render() {
        return(
          <div className="episode-display">
              <div className="episode-header">
                  <strong> Chapters </strong>
              </div>
              <div className="container ep-container">
              {
                  this.state.chapters.map(chapter =>
                    <div className="ep-row" key={chapter.chapter_number}>
                        {chapter.video.map(vid =>
                            <div className="episode-content" key={vid.episode_number}>
                                <Link to={ComputeVideoURL(chapter.chapter_number, vid.episode_number)}>
                                    <div className="play-container">
                                        <img src={vid.thumnail_url} alt={vid.title} />
                                        <span className="mdi mdi-motion-play-outline mdi-light mdi-48px play-icon"></span>
                                    </div>
                                </Link>
                                <div className="episode-id" >
                                    Chapter {chapter.chapter_number}, Episode {vid.episode_number}
                                </div>
                                <div className="episode-title">
                                    {vid.title}
                                </div>
                                <div className="episode-desc">
                                    {vid.description}
                                </div>
                            </div>
                        )}
                    </div>
                  )
              }
              </div>
          </div>
        );
    }
}
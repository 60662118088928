import React, {useState, useEffect} from "react";
import {GetActiveCultivators} from "../apiaccess/cmsAccess";
import HalfBaked from "../components/HalfBaked";

import './styling/cultivators.css';
import {Button} from "react-bootstrap";

export default function Cultivators() {

    const [cultData, setCultData] = useState([]);
    const [errored, setErrored] = useState(false);

    useEffect(() => {
        GetActiveCultivators()
            .then(data => {
                setErrored(data === undefined);
                if (!errored) {
                    setCultData(data);
                }
            })
            .catch(error => {
                console.log(error);
                setErrored(true);
            });
    }, [errored])

    return (
        <div className="cultivators page-content">
            {errored && <HalfBaked/>}

            {!errored && cultData.map(cult =>
                <div key={cult.title} className="cultivator">
                    <div className="left-column">
                        <div className="cult-img-container">
                            <img src={cult.featured_image_url}
                                alt={cult.title + ' Logo'}
                                title={cult.title + ' Logo'} />
                        </div>
                    </div>
                    <div className="cult-content">
                        <div className="cult-desc"> {cult.description} </div>
                        <div className="cult-learn">
                            <Button href={cult.learn_more_url}> Learn More </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
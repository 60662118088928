import {Link, NavLink} from "react-router-dom";

import "./styles/navigation.css";

export default function Navigation(props) {
    return (
        <div className="navigation nav-footer-padding">
                <div className='nav-footer-logo-container'>
                    <Link to="/">
                        <img  src={props.data} alt="Anden sessions logo"/>
                    </Link>
                </div>
                <div className="container-fluid p-0">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/cultivators">
                                        Cultivators
                                    </NavLink>
                                </li>
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/chapters">
                                        Chapters
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
        </div>
    );
}
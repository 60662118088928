// Keystone Graphql API Reference: https://keystonejs.com/docs/apis/graphql
const graphql_url = process.env.REACT_APP_API_URL;

/**
 * Fetches all active Cultivators
 */
export async function GetActiveCultivators() {
    const post_data = JSON.stringify({
        query: `{         
            cultivators(where: {is_active: {equals: true}}) 
            {
                title
                description
                featured_image_url
                learn_more_url
            }
        }`
    });

    const response = await post(post_data);
    const data = await response.json();


    return data.data.cultivators;
}

export async function GetFeaturedCultivators() {
    const post_data = JSON.stringify({
        query: `{         
            cultivators(where: {
              AND:[
                {is_featured: {equals: true}},
                {is_active: {equals: true}}
              ]
              
            }) 
            {
                title
                description
                featured_image_url
                learn_more_url
            }
        }`
    });

    const response = await post(post_data);
    const data = await response.json();


    return data.data.cultivators;
}

/**
 * Fetches All active chapters and their associated (active) videos
 * @param getThumbnail should the function also get the Thumbnail from Vimeo
 * @returns {Promise<*|[]>}
 */
export async function GetActiveChaptersWithActiveVideos(getThumbnail= false) {
    try {
        const post_data = JSON.stringify({
            query: `{
                  chapters(where: { is_active: {equals: true }} 
                  orderBy: {chapter_number: asc}) 
                  {
                    chapter_name
                    chapter_number
                    
                    cultivator
                    {
                        featured_image_url
                    }
                    
                    video(where: {is_active: {equals: true }}
                          orderBy: {episode_number: asc}) 
                    { 
                      episode_number 
                      title
                      oEmbed_url
                      description
                    }
                  }
                }`
        });

        const response = await post(post_data);
        const data = await response.json();

        const chapters = data.data.chapters;

        if (getThumbnail) {
            for (const chap of chapters) {
                for (const vid of chap.video) {
                    const resp = await fetch(vid.oEmbed_url);
                    const vimeo_data = await resp.json();
                    vid.thumnail_url = vimeo_data.thumbnail_url;
                }
            }
        }

        return chapters;
    }
    catch(error) {
        console.log(error);
    }
}

/**
 * Fetches video data for the given Episode number and chapter number
 * @param videoEpisodeNumber - episode number
 * @param chapterNumber - chapter number
 * @returns {Promise<*[]|*>}
 */
export async function GetVideo(videoEpisodeNumber, chapterNumber) {
    try
    {
        const query_str = `{
                    videos(where: {
                        AND:[
                        {chapter: {is_active: {equals: true}}},
                          {chapter: {chapter_number: {equals: ${chapterNumber}}}},
                          {episode_number: {equals: ${videoEpisodeNumber}}}
                          {is_active: {equals: true}},
                        ]} ) 
                    {
                        title  
                        oEmbed_url
                        description
                        
                        chapter {
                          chapter_name
                          chapter_number
                        } 
                        
                        previous_episode {
                          episode_number
                          title
                        }
                        next_episode {
                          episode_number
                          title
                        }
                    }
        }`;
        const post_data = JSON.stringify({
            query: query_str
        });

        const response = await post(post_data);
        const data = await response.json();

        const video = data.data.videos;

        if (Object.keys(video).length === 0) return [];

        for (const vid of video) {
            const resp = await fetch(vid.oEmbed_url);
            const vimeo_data = await resp.json();
            vid.embed_html = vimeo_data.html;
        }

        return video;

    }
    catch(error) {
        console.log(error);
    }
}

/**
 * Used to get data from graphql api
 * */
async function post(post_data) {
    return fetch(
        graphql_url,
        {
            method: 'post',
            mode:'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: post_data,
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': post_data.length,
                'User-Agent': 'Node',
            },
        }
    );
}
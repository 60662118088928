import React from "react";

import './styles/halfbaked.css'
import {Button} from "react-bootstrap";


export default function HalfBaked() {
    return (
      <>
          <div className="baked-container page-content">
              <div className="text">
                  Dude, where's my page!
              </div>
              <Button href="/"> Let's Go Home </Button>
          </div>
      </>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';

import './global.css';

import {BrowserRouter as Router, Route, Routes } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import VideoPlayer from "./pages/VideoPlayer";
import Footer from "./components/Footer";

import logo from "./assets/SessionsAnden-Logo.png";
import HalfBaked from "./components/HalfBaked";
import Cultivators from "./pages/Cultivators";
import Chapters from "./pages/Chapters";


ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Navigation data={logo}/>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/video/chapter-:chapterId/episode-:videoId" element={<VideoPlayer/>}/>
              <Route path="*" element={<HalfBaked/>}>  </Route>
              <Route path="/cultivators" element={<Cultivators/>}> </Route>
              <Route path="/chapters" element={<Chapters/>}> </Route>
          </Routes>
          <Footer data={logo}/>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {Component} from "react";

import HomepageBannerCarousel from "../components/HomepageBannerCarousel";
import FeaturedCultivators from "../components/FeaturedCultivators";
import SocialBanner from "../components/SocialBanner";
import './styling/home.css';
import EpisodesDisplay from "../components/EpisodesDisplay";


export default class Home extends Component {
    async componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                <HomepageBannerCarousel/>
                <div className="description">
                    <div>
                        <strong> Sessions with Anden </strong>  is a series
                        dedicated to showcasing our Anden partners and how
                        they're leaving their mark on the cannabis industry.
                    </div>
                </div>
                <EpisodesDisplay/>
                <FeaturedCultivators/>
                <SocialBanner/>
            </div>
        )
    }
}